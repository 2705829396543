import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Shop from "../components/shop.jsx"

const Loopings = ({location}) => {
  
  return (
    <div>
        <Seo title="Hot Wheels Looping" description="Durchsuchen sie einfach unseren Katalog von Hot Wheels Bahnen und Hot Wheels Autos. Sie können ganz bequem über Amazon bestellen." />
        <Shop header={{
            
            defaultCategory: "Looping"
        }} location={location} />
    </div>
  )
}

export default Loopings
